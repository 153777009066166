import api from '~/api/' // server
// import api from '~/api/mock' // local

const initialState = {
    // 기업개요 fetch 결과
    corp: {
        code: '',
        name: '',
        summary: '',
        priceItems: [], // 종목정보 아래 SupplyDemand표 데이터
        priceList: [], // 주가 차트 데이터
    },
    history: {},
    theme: {},
    date: {
        code: '',
        date: '',
        startDate: '',
        endDate: '',
    },
    /* 20210524 shb 추가 */
    company: {},
}

const state = () => ({
    ...initialState,
})

const mutations = {
    /* 
        220530 meej 종목히스토리 검색 - 구분 타입 조회 
    */
    setNewsTypeList(state, { data }) {
        state[data.newsType] = data.newsTypes
    },
    SET_CORP_CODE(state, code) {
        state.corp.code = code
    },
    SET_CORP_INFO(state, { data }) {
        state.corp = { ...state.corp, ...data }
        // 20210825 shb 수정 | summary undefined 일경우 기존 데이터를 그대로 사용하여 추가
        state.corp.summary = data.summary === undefined ? '기업 개요가 없습니다' : data.summary
    },
    SET_HISTORY_INFO(state, { data }) {
        state.history = { ...state.history, ...data }
         /* 
            220517 meej 신규추천 - 증권사 이름, 추천타입 내용에 추가
            220518 meej 신규추천 - 제외된 종목도 나오게 처리
        */
        if(state.history.recommends !== undefined){
            state.history.recommends.forEach((i)=>{
                i.content = i.content.replaceAll("\r\n","<br>").replaceAll('<br><br>', '<br>')
                i.content = "<span class='blue'>["+i.stockCompanyName + "] </span><span>(" + i.RECOMMEND_TYPE + ") </span>"
                + "<br><div style='margin-left:1em'>"+ i.content + "</div>"
                if(i.EXCLUSION0 === "1"){
                    i.content += "<span style='color:grey'>"
                    if(i.EXCLUDED_DATE0 !== null && i.EXCLUDED_DATE0 !== ''){
                        const YYYY = i.EXCLUDED_DATE0.substring(0, 4)
                        const MM = i.EXCLUDED_DATE0.substring(4, 6)
                        const DD = i.EXCLUDED_DATE0.substring(6, 8)
                        i.EXCLUDED_DATE0 = YYYY + '-' + MM + '-' + DD
                        i.content += i.EXCLUDED_DATE0
                    }
                    if(i.CAUSE !== null && i.CAUSE !== ''){
                        i.content += " "+i.CAUSE
                    }
                    i.content += "</span>"
                }
            })
        }
        
    },
    SET_THEME_INFO(state, { data }) {
        state.theme = { ...state.theme, ...data }
    },
    SET_CORP_PRICE(state, { data }) {
        state.corp.priceList = { ...data }
    },
    /* 20210524 shb 추가 */
    SET_COMPANY(state, data) {
        state.company = data
    },
    // Invalid Date 들어오는 부분은 Getter에서 필터링
    SET_SEARCH_INFO(state, { code, date, startDate, endDate, condition }) {
        state.date = {
            code: '',
            date: '',
            startDate: '',
            endDate: '',
            condition: '',
        }

        state.date = { code, date, startDate, endDate, condition }
    },
    // LifeCycle에서 Destroy될 때, 호출해서 store 초기화
    // TODO: 1차 Freeze 이후, date : '' 로 수정해야함
    CLEAR_STORE(state) {
        state.date = {
            code: '',
            date: '',
            startDate: '',
            endDate: '',
        }
    },
    SET_RESET_ALL_STORE_ITEM(state) {
        state = { ...initialState }
    },
}

const actions = {
    /* 
        220530 meej 종목히스토리 검색 - 구분 타입 조회 
    */
    async ASYNC_NEWSTYPE_LIST({ commit }, params) {
        console.log('GET_NEWSTYPE_LIST params', params)
        const { data } = await api.newsType(params)
        console.log('GET_NEWSTYPE_LIST data', data)

        data.newsType = params.newsType

        commit('setNewsTypeList', { data })
    },
    /* 
        220530 meej 종목히스토리 검색 - 구분 타입 조회 
    */
    GET_NEWSTYPE_LIST({ commit }, params) {
        console.log('GET_NEWSTYPE_LIST params', params)

        return new Promise((resolve, reject) => {
            api.newsType(params)
            .then((response) => {
                const { data } = response
                data.newsType = params.newsType
                commit('setNewsTypeList', { data })
                console.log('GET_NEWSTYPE_LIST data', data)
                resolve(data.newsTypes)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    async GET_CORP_INFO({ commit }, code) {
        // console.warn('GET_CORP_INFO beforeFetch', code)

        const params = { code }

        const data = (await api.getCorpInfo(params)).data

        // console.warn('GET_CORP_INFO data', data)
        commit('SET_CORP_INFO', { data })
    },
    async GET_HISTORY_INFO({ commit }, code) {
        // console.warn('GET_HISTORY_INFO beforeFetch', code)

        const params = { code }

        const { data } = await api.getCorpHistory(params)
        console.log('GETDATA=', data)

        /* 
            220426 meej 종목 입체분석-종목히스토리-종목이슈 내용수정 
                    : 52주신고가 = "52주 신고가 - " + 내용 / 상한가 하한가 = 내용 + 연속일수
            220519 meej 종목 입체분석-종목히스토리-종목이슈 : 강력매수 추가, 줄바꿈 추가
        */
        data.issues.forEach((el)=>{
            /* 
                220527 meej 종목입체분석 title 있어도 content로 안바뀌는 경우가 있어 처리함
             */
            if(el.title !== null && el.title !== ''){
                const titleStr = el.title+""
                if(titleStr.trim() !== '' || titleStr.trim() !== 'null'){
                    el.content = el.title
                }
            }
            if(el.newsType1 === "STOCK_52_WEEKS_NEW_HIGH" ){
                if(el.content !== "" && el.content !== null){
                    el.content = "52주 신고가 - " + el.content
                }else{
                    el.content = "52주 신고가"
                }
            }else if((el.newsType1 === "STOCK_JUMP" || el.newsType1 === "STOCK_SLUMP" )
                        && el.DAYS > 0 ){
                el.content = el.content+" - "+el.DAYS+"일 연속"
            }else if(el.newsType1 === "STOCK_STRONG_BUY"){
                el.content = el.content.replaceAll("\n","<br>").replaceAll("<br><br>", "<br>")
                el.content = el.stockCompanyName + " 강력매수<br>"+el.content
            }
        })

        // console.warn('GET_HISTORY_INFO data', data)
        commit('SET_HISTORY_INFO', { data })
    },
    async GET_THEME_INFO({ commit }, code) {
        // console.warn('GET_THEME_INFO beforeFetch', code)

        const params = { code }

        const { data } = await api.getCorpTheme(params)

        // console.warn('GET_THEME_INFO data', data)
        commit('SET_THEME_INFO', { data })
    },
    async GET_CORP_PRICE({ commit }, pas) {
        // console.warn('GET_CORP_PRICE beforeFetch', pas)

        const params = { ...pas }

        const { data } = await api.getCorpPrice(params)

        // console.warn('GET_CORP_PRICE data', data)
        commit('SET_CORP_PRICE', { data })
    },

    // 날짜 검색 ACTION
    async GET_SEARCH_INFO({ commit, dispatch }, { code, date, startDate, endDate, condition }) {
        // console.warn(
        //   'GET_SEARCH_INFO beforeFetch',
        //   code,
        //   date,
        //   startDate,
        //   endDate
        // )
        await dispatch('paging/RESET_CURRENT_PAGE', null, { root: true })

        commit('SET_SEARCH_INFO', { code, date, startDate, endDate, condition })
    },
    RESET_ALL_STORE_ITEM({ commit }) {
        commit('SET_RESET_ALL_STORE_ITEM')
    },
}

const getters = {
    getCode(state) {
        return state.corp.code
    },

    getSearch(state) {
        const CopyOfState = { ...state.date }

        for (const [key, value] of Object.entries(CopyOfState)) {
            // 필터링할 조건
            if (
                value === 'Invalid Date' ||
                value === '<empty string>' ||
                value === '' ||
                value === undefined
            ) {
                delete CopyOfState[key]
            }
        }

        return CopyOfState
    },
    getSupplyData(state) {
        return state.corp.priceItems
    },
    GETTER_HISTORY(state) {
        return state.history
    },
    GETTER_THEME(state) {
        return state.theme
    },
    GETTER_PRICELIST(state) {
        return state.corp.priceList.items
    },
}

export default {
    state,
    mutations,
    actions,
    getters,
}
