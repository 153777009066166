const _storage = typeof localStorage === 'object' ? localStorage : {}

export const keys = {
    id: '__infostock_id__',
    loginInfo: '__infostock_loginInfo__',
    pathInfo: '__infostock_pathInfo__', // 22.04.19 suye 최근 접속 path저장 변수 
}

const storage = {
    set(key, value) {
        _storage[key] = JSON.stringify(value)
    },
    get(key) {
        if (!_storage[key]) return null
        const value = _storage[key]
        try {
            const parsed = JSON.parse(value)
            return parsed
        } catch (e) {
            return value
        }
    },
    remove(key) {
        delete _storage[key]
    },
    clear() {
        if (_storage.clear) {
            _storage.clear()
        }
    },
}

export default storage
