//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

import Header from '~/components/Common/Layout/Header'
import Footer from '~/components/Common/Layout/Footer'
import MobileUnderFooter from '~/components/Common/Layout/Footer/MobileUnderFooter'
import Alert from '~/components/Common/Modal/Alert'
import LoginModal from '~/components/Common/Modal/LoginModal'
// import FindId from '~/components/Common/Modal/FindId'
import Findinfo from '~/components/Common/Modal/Findinfo'
import RegularMemberJoin from '~/components/Common/Modal/RegularMemberJoin'
import RegularMemberWelcome from '~/components/Common/Modal/RegularMemberWelcome'
import Deposit from '~/components/Common/Modal/Deposit'
import Introduction from '~/components/Common/Modal/Introduction'
import Payment from '~/components/Common/Modal/Payment' // 22.03.18 한수예 결제창 모달 추가 Payment

let bodyEl = null

export default {
    components: {
        Header,
        Footer,
        MobileUnderFooter,
        Alert,
        LoginModal,
        // FindId,
        Findinfo,
        RegularMemberJoin,
        RegularMemberWelcome,
        Deposit,
        Introduction,
        Payment,
    },

    computed: {
        ...mapState('modal', [
            'alertShow',
            'loginShow',
            'findIdShow',
            'regularMemberJoinShow',
            'regularMemberWelcomeShow',
            'depositShow',
            'depositInfo',
            'introduction',
            'payment',
        ]),
        ...mapState('ui', ['mobileMenuShow', 'isOpenPopup', 'isPopupOpen']),
        // ...mapState({ alertShow: (state) => state.modal.show }),

        overlayShow() {
            return (
                this.introduction.show ||
                this.depositInfo.show ||
                this.depositShow ||
                this.mobileMenuShow ||
                this.regularMemberJoinShow ||
                this.regularMemberWelcomeShow ||
                this.findIdShow ||
                this.loginShow ||
                this.alertShow ||
                this.payment
            )
        },
    },

    watch: {
        overlayShow(newVal, oldVal) {
            console.log('overlayShow newVal, oldVal', newVal, oldVal)

            bodyEl = bodyEl || document.getElementsByTagName('body')[0]

            newVal
                ? bodyEl.classList.add('scrollHidden')
                : bodyEl.classList.remove('scrollHidden')
        },
    },

    methods: {
        ...mapActions('modal', ['SHOW_ALERT', 'HIDE_MODAL']),
        ...mapMutations('ui', ['SET_MOBILE_MENU_SHOW', 'SET_ISPOPUP_OPEN']),
        onClickOverLayArea(e) {
            const target = e.target.classList

            if (target.value.includes('container overlay')) {
                this.HIDE_MODAL()
                this.SET_MOBILE_MENU_SHOW(false)
            }
        },

        // 22.07.12 suye 키누르면 모달닫기
        keyClose(){
            this.HIDE_MODAL()
        },
    },
}
