export default [
    { id: 1, title: '모바일주식신문', mobile: false, uri: '/MobileStockPaper' },

    {
        id: 2,
        title: '기업',
        mobile: false,
        uri: '',
        group: [
            {
                id: 21,
                title: '종목 입체분석', // group 타이틀이 있을 경우
                uri: '',
                menus: [
                    {
                        id: 2101,
                        title: '종목 정보',
                        uri: '/Company/Analysis/StockInfo',
                    },
                    {
                        id: 2102,
                        title: '종목 히스토리 차트',
                        uri: '/Company/Analysis/StockHistory',
                    },
                    {
                        id: 2103,
                        title: '종목 테마 차트',
                        uri: '/Company/Analysis/StockThemeChart',
                    },
                    {
                        id: 2104,
                        title: '기업명 변경 종목',
                        uri: '/Company/Analysis/StockNameChanged',
                    },
                    /* 
                        220530 meej 종목 히스토리 검색 추가
                     */
                    {
                        id: 2105,
                        title: '종목 히스토리 검색',
                        uri: '/Company/Analysis/StockHistorySearch',
                    },
                    {
                        id: 2106,
                        title: '주요기업 분기 실적 전망',
                        uri: '/Company/Analysis/Earning',
                    },                    
                ],
            },
            {
                id: 22,
                title: '',
                uri: '',
                menus: [
                    /* 
                        220516 meej 장중 특징주 및 특징테마 추가 
                    */
                    { id: 2211, title: '장중 특징주 및 특징테마', uri: '/Company/MiddleFeatures' },
                    { id: 2201, title: '특징종목', uri: '/Company/Features' },
                    {
                        id: 2202,
                        title: '급등/급락/52주 등 당일이슈종목',
                        uri: '/Company/TodayIssues',
                    },
                    {
                        id: 2203,
                        title: '시간외 단일가 특이종목',
                        uri: '/Company/OvertimeUnusual',
                    },
                    // 22.08.11 suye 급등락종목사유분석 -> 급등/급락/52주 탭으로 변경
                    // {
                    //     id: 2204,
                    //     title: '급등락 종목 사유 분석',
                    //     uri: '/Company/JumpSlumpReason',
                    // },
                    {
                        id: 2205,
                        title: '기술적 분석 특징주',
                        uri: '/Company/TechnicalAnalysis',
                    },
                    {
                        id: 2206,
                        title: '종목 공시',
                        uri: '/Company/Disclosure',
                    },
                    {
                        id: 2207,
                        title: '공매도 비중 상위 종목',
                        uri: '/Company/ShortSellingRanking',
                    },
                    {
                        id: 2208,
                        title: '주식 대차 거래 현황',
                        uri: '/Company/LoanTransaction',
                    },
                    {
                        id: 2209,
                        title: '일별 지분 변동 공시 종목',
                        uri: '/Company/DailyShareChange',
                    },
                    {
                        id: 2210,
                        title: '일별 자사주 체결 공시 종목',
                        uri: '/Company/DailyTreasuryStock',
                    },
                    /* 
                        220530 meej 최근 이슈발생 종목 추가
                     */
                    {
                        id: 2211,
                        title: '최근 이슈발생 종목',
                        uri: '/Company/RecentIssue',
                    },
                ],
            },
            {
                id: 23,
                title: '',
                uri: '',
                menus: [
                    {
                        id: 2301,
                        title: '상승률/하락률 상위 50종목',
                        uri: '/Company/RiseDropRateRanking',
                    },
                    {
                        id: 2302,
                        title: '외국인/기관 순매수/도 상위 20종목',
                        uri: '/Company/ForeignInstBuySellRanking',
                    },
                    {
                        id: 223,
                        title: '외국인/기관 연속 순매수일 상위 20종목',
                        uri: '/Company/ForeignInstContinueRanking',
                    },
                    {
                        id: 224,
                        title: '외국계 순매수/도 상위 20종목',
                        uri: '/Company/ForeignBuySellRanking',
                    },
                    {
                        id: 225,
                        title: '전일 대비 거래량 급증 종목',
                        uri: '/Company/VolumeJump',
                    },
                    {
                        id: 226,
                        title: '종가 동시호가 특이 종목',
                        uri: '/Company/QuoteUnusual',
                    },
                    {
                        id: 227,
                        title: '신용비율 상위 종목',
                        uri: '/Company/CreditRatioRanking',
                    },
                    {
                        id: 228,
                        title: '오늘의 인기 검색 20종목',
                        uri: '/Company/TodayPopularSearch',
                    },
                    {
                        id: 229,
                        title: '변동성 완화 장치 (VI) 발동 종목',
                        subtitle: '(발동시간/발동횟수 기준)',
                        uri: '/Company/VI',
                    },
                    {
                        id: 230,
                        title: '1억원 이상 매수/매도 체결 상위 20종목',
                        subtitle: '(코스피/코스닥)',
                        uri: '/Company/100MBuySellRanking',
                    },
                    {
                        id: 231,
                        title: '시장조치 종목',
                        uri: '/Company/MarketAction',
                    },
                ],
            },
        ],
    },

    {
        id: 3,
        title: '테마',
        mobile: false,
        uri: '',
        group: [
            {
                id: 31,
                title: '',
                uri: '',
                menus: [
                    {
                        id: 3101,
                        title: 'Daily 특징 테마',
                        uri: '/Theme/DailyFeaturedTheme',
                    },
                    {
                        id: 3102,
                        title: '인포스탁 테마 DB',
                        uri: '/Theme/ThemeDB',
                    },
                    // 220617 meej 인포스탁 테마 Index 추가
                    {
                        id: 3105,
                        title: '인포스탁 테마 Index',
                        uri: '/Theme/ThemeIndex',
                    },
                    {
                        id: 3103,
                        title: '테마 브리핑',
                        uri: '/Theme/ThemeBriefing',
                    },
                    {
                        id: 3104,
                        title: '일간/주간/월간 테마별 등락률',
                        uri: '/Theme/ThemeFluctuations',
                    },
                ],
            },
        ],
    },

    {
        id: 4,
        title: '시황',
        mobile: false,
        uri: '',
        group: [
            {
                id: 41,
                title: '',
                uri: '',
                menus: [
                    // 22.05.25 suye 메뉴바 시황-이슈&테마스케줄 추가 
                    {
                        id: 4100,
                        title: '이슈&테마스케줄',
                        uri: '/EventNews/Month',
                    },
                    {
                        id: 4101,
                        title: '당일 증시 요약',
                        uri: '/MarketNews/TodaySummary',
                    },
                    {
                        id: 4102,
                        title: '일간 뉴스클리핑',
                        uri: '/MarketNews/DailyNewsBriefing',
                    },
                    {
                        id: 4103,
                        title: '코스피',
                        uri: '/MarketNews/KOSPI',
                    },
                    {
                        id: 4104,
                        title: '코스닥',
                        uri: '/MarketNews/KOSDAQ',
                    },
                    {
                        id: 4104,
                        title: '선물',
                        uri: '/MarketNews/Futures',
                    },
                    {
                        id: 4104,
                        title: '외환',
                        uri: '/MarketNews/ForeignExchange',
                    },
                    {
                        id: 4104,
                        title: '채권',
                        uri: '/MarketNews/Bond',
                    },
                ],
            },
            {
                id: 42,
                title: '',
                uri: '',
                menus: [
                    {
                        id: 4201,
                        title: '중국',
                        uri: '/MarketNews/China',
                    },
                    {
                        id: 4202,
                        title: '일본',
                        uri: '/MarketNews/Japan',
                    },
                    {
                        id: 4203,
                        title: '대만',
                        uri: '/MarketNews/Taiwan',
                    },
                    {
                        id: 4204,
                        title: '반도체',
                        uri: '/MarketNews/Semicon',
                    },
                ],
            },
            {
                id: 43,
                title: '',
                uri: '',
                menus: [
                    {
                        id: 4301,
                        title: '뉴욕마감',
                        uri: '/MarketNews/NewYorkClosing',
                    },
                    {
                        id: 4302,
                        title: '뉴욕환율',
                        uri: '/MarketNews/NewYorkExchange',
                    },
                    {
                        id: 4303,
                        title: '뉴욕채권',
                        uri: '/MarketNews/NewYorkBond',
                    },
                    {
                        id: 4304,
                        title: '국제유가',
                        uri: '/MarketNews/CrudeOilPrice',
                    },
                    {
                        id: 4305,
                        title: '필라델피아 반도체',
                        uri: '/MarketNews/SOX',
                    },
                ],
            },
            {
                id: 44,
                title: '',
                uri: '',
                menus: [
                    {
                        id: 4401,
                        title:
                            '미국/중국/대만/일본 주요 기업 주가 등락률 (마감)',
                        uri: '/MarketNews/MajorCompaniesFluctuationRate',
                    },
                    {
                        id: 4402,
                        title: '주요 환율 및 반도체/상품 동향',
                        uri: '/MarketNews/ExchangeRate_ProductsTrend',
                    },
                    {
                        id: 4403,
                        title: '국내외 주요 마감지수',
                        uri: '/MarketNews/MajorCountriesIndex',
                    },
                ],
            },
        ],
    },

    {
        id: 5,
        title: '리서치',
        mobile: false,
        uri: '',
        group: [
            {
                id: 51,
                title: '',
                uri: '',
                menus: [
                    // {
                    //     id: 5101,
                    //     title: '인포스탁 인사이트',
                    //     uri: '/Research/InfoStockInsight',
                    // },
                    {
                        id: 5102,
                        title: '증권사 신규 추천',
                        uri: '/Research/StockFirmRecommend/DailyRecommend',
                    },
                    {
                        id: 5103,
                        title: '증권사 투자의견',
                        uri: '/Research/StockFirmInvestmentOpinion',
                    },
                    {
                        id: 5104,
                        title: '증권사 신규보고서 현황',
                        uri: '/Research/StockFirmReport',
                    },
                    {
                        id: 5105,
                        title: '증권사 강력매수',
                        uri: '/Research/StockFirmStrongBuying',
                    },
                    {
                        id: 5106,
                        title: '주요 기업 실적',
                        uri: '/Research/MainEarning',
                    },
                ],
            },
        ],
    },

    {
        id: 6,
        title: '회사 및 서비스 소개',
        mobile: false,
        uri: '',
        group: [
            {
                id: 61,
                title: '',
                uri: '',
                menus: [ // 인포스탁 기업IR /IR_B2B/IR
                   {
                        id: 6101,
                        title: '인포스탁 소개',
                        uri: '/IR_B2B/IRCompanies', 
                    }, 
                    {
                        id: 6102,
                        title: '공지사항',
                        uri: '/CustomerCenter/Notice',
                    },
                    {
                        id: 6103,
                        title: '시황속보 관련 주의사항',
                        uri: '/Care',
                    },
                    //   {
                    //     id: 6103,
                    //     title: 'IR 서비스 소개',
                    //     uri: '/IR_B2B/ServiceIntroduction',
                    //   },
                ],
            },
            {
                id: 62,
                title: '',
                mobile: false,
                uri: '',
                menus: [
                    {
                        id: 6204,
                        title: '속보 서비스',
                        uri: '/IR_B2B/FlashService',
                    },
                    {
                        id: 6205,
                        title: '테마 분류 서비스',
                        uri: '/IR_B2B/ThemeService',
                    },
                    {
                        id: 6206,
                        title: '이슈&테마 스케줄 서비스',
                        uri: '/IR_B2B/ScheduleService',
                    },
                    {
                        id: 6207,
                        title: '주식신문 서비스',
                        uri: '/IR_B2B/FinancialNews',
                    },
                    {
                        id: 6208,
                        title: 'IR 컨설팅 서비스',
                        uri: '/IR_B2B/IR',
                    },
            //         {
            //             id: 6201,
            //             title: '인포스탁 주식신문',
            //             uri: '/IR_B2B/FinancialNews',
            //         },
            //         {
            //             id: 6202,
            //             title: '인포스탁 시황속보 서비스',
            //             uri: '/IR_B2B/FlashService',
            //         },
            //         {
            //             id: 6203,
            //             title: '국내/해외 시황 서비스',
            //             uri: '/IR_B2B/MarketService',
            //         },
            //         {
            //             id: 6204,
            //             title: '인포스탁 테마 서비스',
            //             uri: '/IR_B2B/ThemeService',
            //         },
            //         {
            //             id: 6205,
            //             title: '기업개요 및 종목히스토리 서비스',
            //             uri: '/IR_B2B/HistoryService',
            //         },
            //         {
            //             id: 6206,
            //             title: '컨센서스 데이터 서비스',
            //             uri: '/IR_B2B/DataService',
            //         },
            //         {
            //             id: 6207,
            //             title: '이슈&테마 스케줄 서비스',
            //             uri: '/IR_B2B/ScheduleService',
            //         },
                ],
            },
        ],
    },
    {
        id: 7,
        title: '뉴스/미디어',
        mobile: false,
        uri: '',
        group: [
            {
                id: 71,
                title: '',
                uri: '',
                menus: [
                    {
                        id: 7101,
                        title: '인포스탁 데일리',
                        uri: 'http://www.infostockdaily.co.kr',
                        external: true,
                    },
                    // {
                    //     id: 7102,
                    //     title: '인포스탁 제휴뉴스',
                    //     uri: 'http://www.newstogether.co.kr',
                    //     external: true,
                    // },
                    {
                        id: 7103,
                        title: '최양오의 경제토크',
                        uri: 'http://www.podbbang.com/ch/16922',
                        external: true,
                    },
                    {
                        id: 7104,
                        title: '인포스탁데일리 마감체크',
                        uri: 'https://tv.naver.com/infostockdaily',
                        external: true,
                    },
                    {
                        id: 7105,
                        title: '주목! 이 리포트',
                        uri: 'https://audioclip.naver.com/channels/3056',
                        external: true,
                    },
                ],
            },
        ],
    },
    {
        id: 8,
        title: '이용안내/문의',
        mobile: true,
        uri: '',
        group: [
            {
                id: 81,
                title: '',
                uri: '',
                menus: [
                    {
                        id: 8101,
                        title: '공지사항',
                        uri: '/CustomerCenter/Notice',
                    },
                    {
                        id: 8102,
                        title: '시황속보 관련 주의사항',
                        uri: '/Care',
                    },
                    {
                        id: 8103,
                        title: '개인정보취급방침',
                        uri: '/Policy',
                    },
                    {
                        id: 8104,
                        title: '운영자윤리강령',
                        uri: '/Principle',
                    },
                    {
                        id: 8105,
                        title: '청소년보호정책',
                        uri: '/PolicyYouth',
                    },
                ],
            },
        ],
    },
]
