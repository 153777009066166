import { getKeyByValue } from '~/utils'

export const URIs = {
    'flash/list': [
        /* flash/list menuType  정의  210312 손숙희 */

        'MENU_TECH_ANALYSIS', // 기술적 분석 특징주
        'MENU_DISCLOSURE', // 종목공시
        'MENU_SHORT_SELLING_RANK', // 공매도 비중 상위 종목
        'MENU_LOAN_TRANSACTION', // 주식 대차 거래 현황
        'MENU_STOCK_CHANGE_DISC', // 전일자 주요 지분 변동 공시
        'MENU_TREASURY_STOCK', // 당일 자사주 체결 현황
        'MENU_STOCK_UP_DOWN', // 상승률/하락률 상위 50종목
        'MENU_FOREIGN_INST_BUY_SELL', // 외국인/기관 순매수,도 상위 20종목
        'MENU_FOREIGN_INST_CONTINUE', // 외국인/기관 연속 순매수일 상위 20
        'MENU_FOREIGN_BUY_SELL', // 외국계 순,매수도 상위 20종목
        'MENU_VOLUME_JUMP', // 전일대비 거래량 급증 종목
        'MENU_QUOTE_UNUSUAL', // 종가 동시호가 특이 종목
        'MENU_CREDIT_RATIO', // 신용비율 상위 종목
        'MENU_EARNING', // 주요기업 분기 실적 전망
        'MENU_TODAY_POPULAR', // 오늘의 인기검색 20종목
        'MENU_VI', // 변동성 완화장치(VI) 발동종목, 발동시간/발동 횟수 기준
        'MENU_100M_BUY_SELL', // 1억원이상 매수/매도체결 상위 20종목(코스피/코스닥)
        'MARKET_FLASH_MARKET_ACTION', // 시장조치종목
        'MENU_THEME_FLUCTUATIONS', // 주간/월간/전일 인포스탁 테마별 등락률
        'MENU_TODAY_SUMMARY', // 당일증시요약
        'MENU_MAJOR_SPRF', // 미국/중국/대만/일본 주요기업주가등락률
        'MENU_EXR_PROD_TRAND', // 주요환율 및 반도체/상품동향
        'MENU_MAJOR_COUNTRIES_INDEX', // 해외 주요국 지수
        /* 
            220322 meej
            종목공시 : newsFlash -> marketNews로 변경
        */
        // 'MENU_STOCK_DISCL', // 종목공시
        'MENU_DAILY_FEATURED_THEME',

    ],

    'flash/html': [
        // 증시
        'MARKET_FLASH_KOSDAQ_SUMMARY',
        'MARKET_FLASH_KOSPI_SUMMARY',
        // 기술적 분석 특징주
        'MARKET_FLASH_TA_A_KOSDAQ_AM',
        'MARKET_FLASH_TA_A_KOSPI_AM',
        'MARKET_FLASH_TA_B_KOSDAQ_AM',
        'MARKET_FLASH_TA_B_KOSPI_AM',
        'MARKET_FLASH_TA_A_KOSDAQ_PM',
        'MARKET_FLASH_TA_A_KOSPI_PM',
        'MARKET_FLASH_TA_B_KOSDAQ_PM',
        'MARKET_FLASH_TA_B_KOSPI_PM',
         /* 
            220322 meej
            종목공시 : newsFlash -> marketNews로 변경
        */
        // // 종목공시
        // 'MARKET_FLASH_STOCK_DISC_CLOSED',
        // 'MARKET_FLASH_STOCK_DISC_MIDDLE',
        // 주식 대차 거래 현황
        'MARKET_FLASH_SHORT_SELL_RANK',
        // 주식 대차 거래 현황
        'MARKET_FLASH_LT_REPAY_KOSDAQ',
        'MARKET_FLASH_LT_REPAY_KOSPI',
        'MARKET_FLASH_LT_BAL_DOWN_KOSDAQ',
        'MARKET_FLASH_LT_BAL_DOWN_KOSPI',
        'MARKET_FLASH_LT_BAL_UP_KOSDAQ',
        'MARKET_FLASH_LT_BAL_UP_KOSPI',
        'MARKET_FLASH_LT_CONCLUDE_TOP_KOSDAQ',
        'MARKET_FLASH_LT_CONCLUDE_TOP_KOSPI',
        // 전일자 주요 지분 변동 공시
        'MARKET_FLASH_STACK_CHANGE_DISC',
        // 당일 자사주 체결 현황
        'MARKET_FLASH_DAILY_TREASURY_STOCK',
        // 상승률/하락률 상위 50종목
        'MARKET_FLASH_KOSDAQ_UP_50',
        'MARKET_FLASH_KOSDAQ_DOWN_50',
        'MARKET_FLASH_KOSPI_UP_50',
        'MARKET_FLASH_KOSPI_DOWN_50',
        // 외국인/기관 순매수,도 상위 20종목
        'MARKET_FLASH_INST_20_KOSDAQ',
        'MARKET_FLASH_FOREIGN_20_KOSDAQ',
        'MARKET_FLASH_INST_20_KOSPI',
        'MARKET_FLASH_FOREIGN_20_KOSPI',
        // 외국인/기관 연속 순매수일 상위 20
        'MARKET_FLASH_INST_20_CONTINUE_KOSDAQ',
        'MARKET_FLASH_FOREIGN_20_CONTINUE_KOSDAQ',
        'MARKET_FLASH_INST_20_CONTINUE_KOSPI',
        'MARKET_FLASH_FOREIGN_20_CONTINUE_KOSPI',
        // 외국계 순,매수도 상위 20종목
        'MARKET_FLASH_FOREIGN_INST_PRICE_KOSDAQ',
        'MARKET_FLASH_FOREIGN_INST_PRICE_KOSPI',
        'MARKET_FLASH_FOREIGN_INST_VOL_KOSDAQ',
        'MARKET_FLASH_FOREIGN_INST_VOL_KOSPI',
        // 전일대비 거래량 급증 종목
        'MARKET_FLASH_DOD_TX_JUMP_KOSDAQ',
        'MARKET_FLASH_DOD_TX_JUMP_KOSPI',
        // 종가 동시호가 특이 종목
        'MARKET_FLASH_CP_QUOTE_STOCK',
        // 신용비율 상위 종목
        'MARKET_FLASH_CREDIT_RATIO',
        // 주요기업 실적 전망 발표
        'MARKET_FLASH_EARNING',        
        // 오늘의 인기검색 20종목
        'MARKET_FLASH_POPULAR_20',
        'MARKET_FLASH_POPULAR_20_TODAY',
        // 변동성 완화장치(VI) 발동종목, 발동시간/발동 횟수 기준
        'MARKET_FLASH_VI_BY_TIME',
        'MARKET_FLASH_VI_BY_COUNT',
        // 1억원이상 매수/매도체결 상위 20종목(코스피/코스닥)
        'MARKET_FLASH_100M_SELL_20_KOSDAQ',
        'MARKET_FLASH_100M_BUY_20_KOSDAQ',
        'MARKET_FLASH_100M_SELL_20_KOSPI',
        'MARKET_FLASH_100M_BUY_20_KOSPI',
        // 시장조치 종목
        'MARKET_FLASH_TEXT',
        // 주간/월간/전일 인포스탁 테마별 등락률
        'MARKET_FLASH_PREVDAY_THEME_FR',
        'MARKET_FLASH_WEEKLY_THEME_FR',
        'MARKET_FLASH_MONTHLY_THEME_FR',
        // 일간뉴스클리핑
        'MARKET_FLASH_BEFORE_ISSUE',
        'MARKET_FLASH_AFTER_ISSUE',
        // 미국/중국/대만/일본 주요기업주가등락률
        'MARKET_FLASH_TAIWAN_SPFR',
        'MARKET_FLASH_JAPAN_SPFR',
        'MARKET_FLASH_CHINA_SPFR',
        'MARKET_FLASH_USA_SPFR',
        // 주요환율 및 반도체/상품동향
        'MARKET_FLASH_EXR_PRODUTS_TRAND',
        // 국내외 주요 마감지수
        'MARKET_FLASH_MAJOR_COUNTRIES_INDEX',
        // 증시요약
        'MARKET_FLASH_TECH_ANALYSIS_B_KOSDAQ',
        'MARKET_THEME_DAILY',
        // 일별 지분 변동 공시 종목
        'MARKET_FLASH_STOCK_CHANGE_DISC'
    ],

    'common/period': [],
    '/market/list, market/pdf': [
        'MENU_DAILY_NEWS_BRIEFING', // 일간뉴스클리핑
        'MARKET_INFOSTCOK_INSIGHT',
        'MENU_DAILY_THEME_BRIEFING', // 테마 브리핑
        'MENU_MOBILENEWS',
        'MENU_STOCK_HC',
    ],
    '/market/board, /market/detail': [
        'MARKET_KOSPI',
        'MARKET_KOSDAQ',
        'MARKET_FUTURES',
        'MARKET_FOREX_DOMESTIC',
        'MARKET_FOREX_NEWYORK',
        'MARKET_BOND',
        'MARKET_BOND_NEWYORK',
        'MARKET_CHINA',
        'MARKET_JAPAN',
        'MARKET_TAIWAN',
        'MARKET_SEMICON',
        'MARKET_NEWYORK',
        'MARKET_OIL_PRICE',
        'MARKET_PHILLY_SEMICON',
        'MARKET_HIGH_COMMENT',
        'MARKET_BEFORE_ISSUE',
        'MARKET_AFTER_ISSUE',
        // 20210504 shb daily 특징테마
        'MENU_DAILY_FEATURED_THEME',
        'MARKET_THEME_NEWS',
        'MARKET_THEME_TRAND_PM',
        'MARKET_THEME_TRAND_AM',
        /* 
            220322 meej
            종목공시 : newsFlash -> marketNews로 변경
        */
        'MENU_STOCK_DISCL', // 종목 공시
        'MARKET_STOCK_DISC_CLOSED', // 종목공시(장마감)
        'MARKET_STOCK_DISC_MIDDLE', // 종목공시(장중)
        
        /* 
            220516 meej 장중 특징주 및 특징테마_임시로 추가
        */
        'MENU_MIDDLE_FEATURED', // 장중 특징주 및 특징테마
        'MARKET_FLASH_AM_FEATURED_KOSDAQ', 
        'MARKET_FLASH_AM_FEATURED_KOSPI',
        'MARKET_FLASH_PM_FEATURED_KOSPI',
        'MARKET_FLASH_PM_FEATURED_KOSDAQ',
        'MARKET_FLASH_THEME_AM',
        'MARKET_FLASH_THEME_PM'
    ],
    'stock/news/list': [
        'MENU_STOCK_FEATURED',
        'MENU_STOCK_ISSUE',
        'STOCK_JUMP',
        'STOCK_SLUMP',
        'STOCK_52_WEEKS_NEW_HIGH',
        'MENU_STOCK_OU',
        'MENU_STOCK_OPINION',
        'MENU_STOCK_SB',
        'STOCK_OVERTIME_UNUSUAL',
        'STOCK_HIGH_COMMENT',
    ],

    '/theme/news/board, /theme/news/detail': [],

    '/stock/news/board, /stock/news/detail': [
        // Company/Features
        'MARKET_FEATURED_STOCKS',
        // Company/JumpSlumpReason
        'STOCK_HIGH_COMMENT',
    ],

    'research/mainEarning': [
        'MENU_MAIN_EARNING', // 주요기업실적
    ],
    'board/list': [
        // 공지사항
        'BOARD_NOTICE',
        // 1:1문의
        'BOARD_ONE_ON_ONE',
        // 무통장입금
        'BOARD_DEPOSIT',
    ],

    // 'board/write': [
    //     // 1:1문의
    //     'BOARD_ONE_ON_ONE',
    //     // 무통장입금
    //     'BOARD_DEPOSIT',
    // ],

    'board/ad/list': [
        // 광고게시판
        'BOARD_AD',
    ],
}

export function getURI(newsType) {
    console.log('getURI newsType', newsType)
    // for (const key in URIs) {
    //   // if (object.hasOwnProperty(key)) {
    //   const element = URIs[key]
    //   // }
    // }
    const URI = getKeyByValue(URIs, newsType)
    console.log('getURI URI', URI)
    if (!URI) {
        return
    }

    return getKeyByValue(URIs, newsType).replace(/\s+/g, '')
}

export function getFileType(newsType) {
    console.log('getFileType newsType', newsType)
    console.log('Filetype' + JSON.stringify(FileType))
    const URI = getKeyByValue(FileType, newsType)
    console.log('getFileType URI', URI)
    if (!URI) {
        return
    }

    return getKeyByValue(FileType, newsType).replace(/\s+/g, '')
}

// board,blog detail 관련
export const FileType = {
    pdf: [
        'MARKET_THEME_BRIEFING',
        'MARKET_INFOSTCOK_INSIGHT',
        'MARKET_AFTER_ISSUE',
        'MARKET_BEFORE_ISSUE',
    ],
    html: [
        // 'MENU_THEME_FLUCTUATIONS',
        // 'MARKET_TECH_ANALYSIS',
        // 'MARKET_DISCLOSURE',
        // 'MENU_STOCK_DISCL',
        // 'MARKET_LOAN_TRANSACTION',
        // 'MARKET_DAILY_SHARE_CHANGE',
        // 'MARKET_DAILY_TREASURY_STOCK',
        // 'MARKET_RISED_DROP_RATE_RANK',
        // 'MARKET_FOREIGN_INST_BUY_SELL_RANK',
        // 'MARKET_FOREIGN_INST_CONTINUE_RANK',
        // 'MARKET_FOREIGN_BUY_SELL_RANK',
        // 'MARKET_VOLUME_JUMP',
        // 'MARKET_QUOTE_UNUSUAL',
        // 'MARKET_CREDIT_RATIO_RANK',
        // 'MARKET_TODAY_POPULAR_SEARCH',
        // 'MARKET_VI',
        // 'MARKET_100M_BUY_SELL_RANK',
        // 'MARKET_MAJOR_COMPANIES_FLUCTUATION_RATE',
        // 'MARKET_EXCHANGERAGE_PROCUTS_TRAND',
        // 'MARKET_MAJOR_COUNTRIES_INDEX',
        // 'MARKET_HIGH_COMMENT',
        // 기술적 분석 특징주
        'MARKET_FLASH_TA_A_KOSDAQ_AM',
        'MARKET_FLASH_TA_A_KOSPI_AM',
        'MARKET_FLASH_TA_B_KOSDAQ_AM',
        'MARKET_FLASH_TA_B_KOSPI_AM',
        'MARKET_FLASH_TA_A_KOSDAQ_PM',
        'MARKET_FLASH_TA_A_KOSPI_PM',
        'MARKET_FLASH_TA_B_KOSDAQ_PM',
        'MARKET_FLASH_TA_B_KOSPI_PM',
        // 종목공시
        // 'MARKET_FLASH_STOCK_DISC_CLOSED',
        // 'MARKET_FLASH_STOCK_DISC_MIDDLE',
        // 주식 대차 거래 현황
        'MARKET_FLASH_SHORT_SELL_RANK',
        // 주식 대차 거래 현황
        'MARKET_FLASH_LT_REPAY_KOSDAQ',
        'MARKET_FLASH_LT_REPAY_KOSPI',
        'MARKET_FLASH_LT_BAL_DOWN_KOSDAQ',
        'MARKET_FLASH_LT_BAL_DOWN_KOSPI',
        'MARKET_FLASH_LT_BAL_UP_KOSDAQ',
        'MARKET_FLASH_LT_BAL_UP_KOSPI',
        'MARKET_FLASH_LT_CONCLUDE_TOP_KOSDAQ',
        'MARKET_FLASH_LT_CONCLUDE_TOP_KOSPI',
        // 전일자 주요 지분 변동 공시
        'MARKET_FLASH_STACK_CHANGE_DISC',
        // 당일 자사주 체결 현황
        'MARKET_FLASH_DAILY_TREASURY_STOCK',
        // 상승률/하락률 상위 50종목
        'MARKET_FLASH_KOSDAQ_UP_50',
        'MARKET_FLASH_KOSDAQ_DOWN_50',
        'MARKET_FLASH_KOSPI_UP_50',
        'MARKET_FLASH_KOSPI_DOWN_50',
        // 외국인/기관 순매수,도 상위 20종목
        'MARKET_FLASH_INST_20_KOSDAQ',
        'MARKET_FLASH_FOREIGN_20_KOSDAQ',
        'MARKET_FLASH_INST_20_KOSPI',
        'MARKET_FLASH_FOREIGN_20_KOSPI',
        // 외국인/기관 연속 순매수일 상위 20
        'MARKET_FLASH_FOREIGN_INST_20_SELL_CONTINUE_KOSDAQ',
        'MARKET_FLASH_FOREIGN_INST_20_SELL_CONTINUE_KOSPI',
        'MARKET_FLASH_FOREIGN_INST_20_BUY_CONTINUE_KOSDAQ',
        'MARKET_FLASH_FOREIGN_INST_20_BUY_CONTINUE_KOSPI',
        // 외국계 순,매수도 상위 20종목
        'MARKET_FLASH_FOREIGN_INST_PRICE_KOSDAQ',
        'MARKET_FLASH_FOREIGN_INST_PRICE_KOSPI',
        'MARKET_FLASH_FOREIGN_INST_VOL_KOSDAQ',
        'MARKET_FLASH_FOREIGN_INST_VOL_KOSPI',
        // 전일대비 거래량 급증 종목
        'MARKET_FLASH_DOD_TX_JUMP_KOSDAQ',
        'MARKET_FLASH_DOD_TX_JUMP_KOSPI',
        // 종가 동시호가 특이 종목
        'MARKET_FLASH_CP_QUOTE_STOCK',
        // 신용비율 상위 종목
        'MARKET_FLASH_CREDIT_RATIO',
        // 오늘의 인기검색 20종목
        'MARKET_FLASH_POPULAR_20',
        'MARKET_FLASH_POPULAR_20_TODAY',
        // 변동성 완화장치(VI) 발동종목, 발동시간/발동 횟수 기준
        'MARKET_FLASH_VI_BY_TIME',
        'MARKET_FLASH_VI_BY_COUNT',
        // 1억원이상 매수/매도체결 상위 20종목(코스피/코스닥)
        'MARKET_FLASH_100M_SELL_20_KOSDAQ',
        'MARKET_FLASH_100M_BUY_20_KOSDAQ',
        'MARKET_FLASH_100M_SELL_20_KOSPI',
        'MARKET_FLASH_100M_BUY_20_KOSPI',
        // 주간/월간/전일 인포스탁 테마별 등락률
        'MARKET_FLASH_PREVDAY_THEME_FR',
        'MARKET_FLASH_WEEKLY_THEME_FR',
        'MARKET_FLASH_MONTHLY_THEME_FR',
        // 일간뉴스클리핑
        'MARKET_FLASH_BEFORE_ISSUE',
        'MARKET_FLASH_AFTER_ISSUE',
        // 미국/중국/대만/일본 주요기업주가등락률
        'MARKET_FLASH_TAIWAN_SPFR',
        'MARKET_FLASH_JAPAN_SPFR',
        'MARKET_FLASH_CHINA_SPFR',
        'MARKET_FLASH_USA_SPFR',
        // 주요환율 및 반도체/상품동향
        'MARKET_FLASH_EXR_PRODUTS_TRAND',
        // 국내외 주요 마감지수
        'MARKET_FLASH_MAJOR_COUNTRIES_INDEX',
        /* 20210504 shb 추가 마감시황 */
        'MARKET_FLASH_KOSDAQ_SUMMARY',
        'MARKET_FLASH_KOSPI_SUMMARY',
        /* 20210511 shb 추가  증시요약 */
        'MARKET_FLASH_TECH_ANALYSIS_B_KOSDAQ',
        'MARKET_FLASH_TECH_ANALYSIS_B_KOSPI',
        'MARKET_FLASH_TECH_ANALYSIS_A_KOSDAQ',
        'MARKET_FLASH_TECH_ANALYSIS_A_KOSPI',
        'MARKET_THEME_DAILY',
        'MARKET_FLASH_STOCK_FEATURED_KOSDAQ',
        'MARKET_FLASH_STOCK_FEATURED_KOSPI',
        'MARKET_FLASH_STOCK_JUMP',
        'MARKET_FLASH_THEME_FEATURED',
        'MENU_MAIN_EARNING', // 주요기업실적 
    ],
    popup: [
        // 테스트를 위한 임시 적용
        // 'MAREKT_THEME_NEWS',
        'MENU_MOBILENEWS',
        'MARKET_MOBILENEWS_AM',
        'MARKET_MOBILENEWS_PM',
        'MARKET_THEME_TRAND_PM',
        'MARKET_THEME_TRAND_AM',
    ],
}
